import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { getBg, space } from "../theme"
import useBreakpoints from "../utils/use-breakpoint"

import Text from "../components/text"
import { Link } from "../components/link"
import Img from "../components/img"
import Physics from "../components/physics"
import Animation from "../components/animation"
import Anchor from "../components/anchor"

export type Props = {
  data: {
    title: string
    subtitle: string
    copy: string
    list: { copy: string; color: ColorKeys }[]
    animation?: string
    image?: ImageType
    link: LinkType
    backgroundColor: ColorKeys
  }
  options?: {
    anchor?: { id: string; top: string }
  }
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
`

const LimitWiderPart = styled.div<{ hasPadding?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 ${p => (p.hasPadding ? 0 : 2)}rem;
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
    margin: ${space("normal")} auto;
  }
`

const BG = styled.div<{ bg?: ColorKeys }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${getBg};
`

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  justify-items: center;
  ${up("desktop")} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  justify-items: flex-start;
  padding: ${space("large")} ${p => (p.hasPadding ? 2 : 0)}rem;
  ${up("desktop")} {
    padding: 10rem 0;
    max-width: 480px;
  }
`

const Item = styled.li<{ bg?: ColorKeys }>`
  position: relative;
  padding-left: 1.5rem;
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: ${getBg};
  }
`
const ListContainer = styled.ul`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 0.2rem;
  align-items: flex-start;
  justify-items: flex-start;
`

const AnimationContainer = styled(Animation)`
  position: relative;
  width: 100%;
  height: auto;
  ${up("tablet")} {
    width: 360px;
    height: 510px;
  }
`

const CardWithList: React.FC<Props> = ({
  data: {
    title,
    subtitle,
    copy,
    list,
    backgroundColor,
    image,
    animation,
    link,
  },
  options,
}) => {
  const breakpoint = useBreakpoints({
    defaultValue: "unknown",
    config: { mobile: 0, tablet: 767, desktop: 1145 },
  })

  return (
    <Wrapper>
      <LimitWiderPart
        hasPadding={!animation && !image && breakpoint === "mobile"}
      >
        {options && options.anchor && (
          <Anchor id={options.anchor.id} top={options.anchor.top} />
        )}
        <BG bg={backgroundColor} />
        <Container>
          {animation && <AnimationContainer path={animation} />}
          {image && (
            <Img
              src={image.src}
              alt={image.alt}
              mw="100%"
              mh="auto"
              tw="480px"
              th="480px"
              dw="auto"
              dh="100%"
              fit="contain"
            />
          )}
          {!animation && !image && breakpoint !== "unknown" && (
            <Physics key={`block3-content-physics-0`} breakpoint={breakpoint} />
          )}
          <Content hasPadding={!animation && !image && breakpoint === "mobile"}>
            <Text color="white">{title}</Text>
            <Text color="white" type="h4">
              {subtitle}
            </Text>
            <ListContainer>
              {list.map((l, idx) => (
                <Item
                  key={`item-list-why-choose-us-${idx}`}
                  bg={l.color || "blue"}
                >
                  <Text color="white">{l.copy}</Text>
                </Item>
              ))}
            </ListContainer>
            <Text color="white">{copy}</Text>
            <Link to={link.to}>{link.copy}</Link>
          </Content>
        </Container>
      </LimitWiderPart>
    </Wrapper>
  )
}

export default CardWithList
