import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { up } from "styled-breakpoints"

import { space } from "../theme"

import Title from "../components/title"
import Text from "../components/text"
import Layout from "../components/layout"
import CardWithList from "../blocks/CardWithList"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  justify-content: center;
  padding: 0 2rem;
  margin: ${space("normal")} auto;
  margin-top: ${space("large")};
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`

const WhyChooseUs = () => (
  <Layout>
    <Helmet>
      <title>Paybase - Why choose us</title>
      <meta property="og:title" content="Paybase - Why choose us" />
    </Helmet>
    <Title copy="Why choose us?" />
    <Wrapper>
      <Container>
        <Text color="trueblack">
          Our API-driven solution enables you to seamlessly integrate
          market-leading features that are all available out-of-the-box. No
          custom development, no lengthy timelines, no enterprise-level costing
          that is usually associated with bespoke innovation. It is completely
          white label, giving you access to escrow services, lightning-fast
          payments, end-to-end support and more - and all whilst retaining
          absolute control.
        </Text>
      </Container>
    </Wrapper>
    <CardWithList
      data={{
        title: "Structured Flexibility",
        subtitle: "One solution, endless possibilities_",
        copy:
          "The businesses of tomorrow need more than a one-size-fits-all payments solution. That’s why at Paybase, we give you access to unprecedented levels of flexibility, accommodating a wide array of use cases and giving you the power to build exactly the product you want.",
        list: [
          { copy: "Route payments the way you want", color: "red" },
          { copy: "Stand out from the competition", color: "darkgreen" },
          {
            copy: "Build the product you want with no compromises",
            color: "yellow",
          },
        ],
        // image: {
        //   src: "/why-choose-us/img1.svg",
        //   alt: "placeholder image",
        // },
        animation: "/animations/why-choose-us/structured-flexibility.json",
        link: {
          to: "/how-we-do-it",
          copy: "Find out how",
        },
        backgroundColor: "blue",
      }}
      options={{
        anchor: { id: "flexibility", top: "-140px" },
      }}
    />
    <CardWithList
      data={{
        title: "Product Enhancement",
        subtitle: "Leverage payments to your advantage_",
        copy:
          "In an oversaturated market, the ability to differentiate from competitors is becoming critical. Offer market-leading features like configurable loyalty programmes, customisable fee structures, escrow functionality and more.",
        list: [
          { copy: "Customisable fee structures", color: "red" },
          { copy: "Escrow functionality", color: "darkgreen" },
          {
            copy: "Configurable loyalty programmes",
            color: "yellow",
          },
        ],
        link: {
          to: "/how-we-do-it",
          copy: "Find out how",
        },
        backgroundColor: "rose",
      }}
      options={{
        anchor: { id: "product", top: "-140px" },
      }}
    />
    <CardWithList
      data={{
        title: "Service Obsessed",
        subtitle: "A partnership, not a plug-in_",
        copy:
          "Too many payment solutions provide a disengaging plug-in service, leaving clients with just a toolkit and instructions. We go the extra mile to learn exactly what your business needs, ensuring that your product requirements are met and future-proofed.",
        list: [
          { copy: "Total peace of mind", color: "red" },
          { copy: "Support at every stage of your growth", color: "blue" },
          {
            copy: "Leverage payments to your competitive advantage",
            color: "yellow",
          },
        ],
        // image: {
        //   src: "/why-choose-us/img3.svg",
        //   alt: "placeholder image",
        // },
        animation: "/animations/why-choose-us/service-obsessed.json",
        link: {
          to: "/how-we-do-it",
          copy: "Find out how",
        },
        backgroundColor: "darkgreen",
      }}
      options={{
        anchor: { id: "service", top: "-140px" },
      }}
    />
    <CardWithList
      data={{
        title: "Financial Crime Prevention",
        subtitle: "Intelligent transaction monitoring built around you",
        copy:
          "Platform businesses face specific financial crime-related challenges. Our granular, innovation-driven approach to compliance gives our clients the most thorough and accurate fraud prevention framework that has ever been possible.",
        list: [
          {
            copy: "Immediately action suspicious activity",
            color: "darkgreen",
          },
          { copy: "UX-driven Due Diligence", color: "darkgreen" },
          {
            copy: "Strong security giving you peace of mind",
            color: "yellow",
          },
        ],
        // image: {
        //   src: "/why-choose-us/img4.svg",
        //   alt: "placeholder image",
        // },
        animation: "/animations/why-choose-us/crime-prevention.json",
        link: {
          to: "/how-we-do-it",
          copy: "Find out how",
        },
        backgroundColor: "red",
      }}
      options={{
        anchor: { id: "crime", top: "-140px" },
      }}
    />
  </Layout>
)

export default WhyChooseUs
